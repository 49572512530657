<template>
  <div style="height: 400px"><canvas ref="myChart" width="500" height="200"></canvas></div>
</template>
<script>
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment'
export default {
  name: '',
  props: ['options', 'data', 'labels', 'unit', 'activeChartFilter'],
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    data (val) {
      this.chart.data.datasets[0].label = this.unit && this.unit != null ? 'Value (' + this.unit + ')' : 'Value'
      this.chart.update()
    }
  },
  methods: {
    init () {
      this.chart = new Chart(this.$refs.myChart.getContext('2d'), {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.unit && this.unit != null ? 'Value (' + this.unit + ')' : 'Value',
              borderColor: '#1d57b9',
              backgroundColor: 'rgba(0,0,0,0)',
              data: this.data
            }
          ]
        },
        options: this.options
      })
    }
  }
}
</script>
