<template>
  <canvas ref="myChart" width="180" height="100"></canvas>
</template>
<script>
import Chart from 'chart.js/auto'
export default {
  name: 'logsummary',
  props: ['chartData', 'chartLabels', 'options', 'label'],
  data () {
    return {
      chart: null
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    chartData (val) {
      this.chart.update()
    }
  },
  methods: {
    init () {
      this.chart = new Chart(this.$refs.myChart.getContext('2d'), {
        type: 'bar',
        data: {
          labels: this.chartLabels,
          datasets: [{
            label: this.label,
            backgroundColor: '#1d57b9',
            data: this.chartData
          }]
        },
        options: this.options
      })
    }
  }
}
</script>
