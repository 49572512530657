<template>
  <PageTemplate :hideTitle="true">
    <template slot="content">
      <!-- <div>
        <em>
          <small class="d-none d-xl-block text-end mb-2" style="margin-top: -30px">Refresh in {{countdown}} seconds</small>
          <span  class="d-block d-xl-none text-center mb-2" style="margin-top: -15px">Refresh in {{countdown}} seconds</span>
        </em>
      </div> -->
      <div :class="isMobileView ? '' : 'd-flex' ">
        <div :class="isMobileView ? '' : 'me-4'" :style="{width: isMobileView ? '100%' : '320px'}">
          <LocationSelection />
          <div class="card" style="height: auto">
            <div class="card-body p-3">
              <label class="form-label font-w600">Appliance</label>
              <select class="form-select form-control" v-model="selectedApp" @change="runInterval()" >
                <option v-for="(list, index) in appliances" :value="list.id" :key="index">{{list.name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)', maxWidth: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card mb-4"  ref="detailsWrapper" style="height: auto">
            <div class="card-body" v-if="sensorStatus.length">
              <div class="row g-2">
                <div v-for="(item, i) in sensorStatus" :key="i" class="col-6 col-md-6 col-sm-6 col-lg-4 col-xl-3" @click="item.data_type !== 'Boolean' ? runInterval(item.name) : null">
                  <div class="jobs d-flex justify-content-between mb-2" :class="item.name == selected ? 'active-card' : 'cursor-pointer'">
                    <Chart :sensor="item" :selected="item.name" />
                  </div>
                </div>
                <!-- <div class="col-6 col-md-6 col-sm-6 col-lg-4 col-xl-3" @click="tpc.data_type !== 'Boolean' ? runInterval(tpc.name) : null">
                  <div class="jobs d-flex justify-content-between mb-2" :class="tpc.name == selected ? 'active-card' : 'cursor-pointer'">
                    <Chart :sensor="tpc" :selected="tpc.name" />
                  </div>
                </div> -->
              </div>
            </div>
            <div class="card-body" v-else>
              <div class="text-center align-items-center justify-content-center d-flex m-auto" style="max-width: 300px; height: 100%">
                <em class="mb-5">No Data Available</em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-5"  style="height: auto" v-if="sensorStatus.length">
        <div class="card-body">
          <div class="d-flex">
            <h4 class="flex-grow-1">Chart - <span class="text-primary">{{selected}}</span></h4>
            <div class="btn-group me-2">
              <button type="button" class="btn btn-primary dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                {{selected}}
              </button>
              <ul class="dropdown-menu">
                <template v-for="(item, i) in sensorStatus"  >
                  <li :key="i"><button class="dropdown-item"  v-if="item.data_type !== 'Boolean'" href="#" @click="runInterval(item.name)" >{{item.name}}</button></li>
                </template>
                <li><button class="dropdown-item"  href="#" @click="runInterval(tpc.name)" >{{tpc.name}}</button></li>
              </ul>
            </div>
            <div class="btn-group" v-if="this.selected !== 'Total Power Consumption'">
              <button type="button" class="btn btn-primary dropdown-toggle btn-sm" data-bs-toggle="dropdown" aria-expanded="false">
                {{activeChartFilter}}
              </button>
              <ul class="dropdown-menu" >
                <li><button class="dropdown-item" href="#" @click="activeChartFilter = 'Latest', getSensorData()">Latest</button></li>
                <!-- <li><button class="dropdown-item" href="#" @click="activeChartFilter = '3 Hours'">3 Hours</button></li>
                <li><button class="dropdown-item" href="#" @click="activeChartFilter = '6 Hours'">6 Hours</button></li>
                <li><button class="dropdown-item" href="#" @click="activeChartFilter = '12 Hours'">12 Hours</button></li> -->
                <li><button class="dropdown-item" href="#" @click="activeChartFilter = '24 Hours', getSensorData()">24 Hours</button></li>
                <li><button class="dropdown-item" href="#" @click="activeChartFilter = '7 Days', getSensorData()">7 days</button></li>
                <li><button class="dropdown-item" ref="customRangeBtn" data-bs-toggle="modal" data-bs-target="#customRangeDialog">Custom Date</button></li>
              </ul>
            </div>
            <button type="button" v-else class="btn btn-primary btn-sm" ref="customRangeBtn" data-bs-toggle="modal" data-bs-target="#customRangeDialog">Custom Date</button>
          </div>
          <ChartLine height="300" v-if="selected !== null" :data="chartData" :labels="chartLabels" :unit="getUnit(selected)"
            :activeChartFilter="activeChartFilter"
            :options="options" class="mt-5" />
        </div>
      </div>
      <!--Dialog-->
      <Dialog :id="'customRangeDialog'"  centered title="Custom Date & Time" >
        <template slot="content" >
          <div class="row">
            <div class="col-md-12 py-3" >
              <label  class="form-label font-w600">Start Date & Time<span class="text-danger scale5 ms-2"> *</span></label>
              <datetime class="custom-datetime-picker" format="YYYY-MM-DD HH:mm:ss"  v-model="startDateTime"></datetime>
              <!-- <VueDatePicker
                v-else
                noHeader
                no-calendar-icon
                class="custom-datepicker form-control"
                :type="'month'"
                :format="'YYYY-MM'"
                placeholder=""
                v-model="startMonth" /> -->
            </div>
            <div class="col-md-12 py-3" >
              <label  class="form-label font-w600">End Date<span class="text-danger scale5 ms-2"> *</span></label>
              <datetime class="custom-datetime-picker" format="YYYY-MM-DD HH:mm:ss" v-model="endDateTime"></datetime>
              <!-- <VueDatePicker
                v-else
                noHeader
                no-calendar-icon
                class="custom-datepicker form-control"
                :type="'month'"
                :format="'YYYY-MM'"
                placeholder=""
                v-model="endMonth" /> -->
            </div>
          </div>
        </template>
        <template slot="footer">
          <a class="btn btn-danger btn-sm  light" ref="closeCustomRangeDialogBtn" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#customRangeDialog">cancel</a>
          <button class="btn btn-primary btn-sm" v-if="this.selected !== 'Total Power Consumption'" :disabled="isLoading || !startDateTime || !endDateTime" @click="setCustomRange" >Submit</button>
          <button class="btn btn-primary btn-sm" v-else :disabled="isLoading || !startMonth || !endMonth" @click="setCustomRange" >Submit</button>
        </template>
      </Dialog>
    </template>
  </PageTemplate>
</template>
<script>
import Dialog from '@/components/Templates/Dialog'
import PageTemplate from '@/components/Templates/PageTemplate'
import LocationSelection from '@/components/LocationSelection'
import Chart from '@/components/Templates/ChartSensor'
import ChartLine from '@/components/Templates/ChartLine'
import datetime from 'vuejs-datetimepicker'
import moment from 'moment'
export default {
  name: 'Sensor',
  components: {
    Dialog,
    PageTemplate,
    LocationSelection,
    Chart,
    ChartLine,
    datetime
    // VueDatePicker
  },
  data () {
    return {
      appliances: [],
      selectedApp: null,
      isLoading: false,
      lastUpdate: null,
      sensorStatus: [],
      sensorData: [],
      chartData: [],
      chartLabels: [],
      lastData: null,
      selected: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
          mode: 'index'
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          y: {
            title: {
              display: true,
              text: 'Value'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Time'
            }
          }
        },
        elements: {
          line: {
            tension: 0
          }
        }
      },

      startDateTime: null,
      endDateTime: null,

      // interval Id
      intervalId: '',
      countdown: 0,
      countdownIntervalId: '',

      activeChartFilter: 'Latest',
      firstLoad: true,

      // Dialog
      customRangeDialog: false,
      optionsPicker: {
        format: 'DD/MM/YYYY hh:mm:00',
        useCurrent: false,
        showClear: true,
        showClose: true
      },

      // total power consumption
      tpc: {},
      startMonth: null,
      endMonth: null
    }
  },
  created () {
    this.$emit('pageTitle', 'Appliance Variables')
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    }
  },
  watch: {
    getSelectedLocation: {
      deep: true,
      handler (val) {
        this.selectedApp = null
        this.runInterval()
      }
    },
    selected (val) {
      if (val === 'Total Power Consumption') this.activeChartFilter = 'Custom Date'
      this.firstLoad = true
    },
    selectedApp (val) {
      this.firstLoad = true
      this.selected = null
      // if (val !== null) this.runInterval()
    }
    /* activeChartFilter (val) {
      console.log(this.firstLoad)
      if (val !== 'Custom Date' &&) {
        this.getSensorData()
      }
    } */
  },
  methods: {
    getUnit (name) {
      const selectedUnit = this.sensorStatus.find(x => x.name === name)
      const unit = selectedUnit && selectedUnit.unit !== null ? selectedUnit.unit : ''
      return unit
    },
    runInterval (newSelection) {
      if (newSelection && newSelection !== null) {
        this.selected = newSelection
        this.activeChartFilter = newSelection === 'Total Power Consumption' ? 'Custom Date' : 'Latest'
      }
      clearInterval(this.intervalId)
      clearInterval(this.countdownIntervalId)
      this.init()
      this.countDown()
      this.intervalId = setInterval(function () {
        this.countDown()
        this.init()
      }.bind(this), this.$config.apiRequestInterval)
    },
    countDown () {
      clearInterval(this.countdownIntervalId)
      this.countdown = this.$config.apiRequestInterval / 1000
      this.countdownIntervalId = setInterval(function () {
        this.countdown = this.countdown - 1
      }.bind(this), 1000)
    },
    getTotalPowerConsumption () {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getTotalPowerConsumption', { locationId: this.getSelectedLocation.id, applianceId: this.selectedApp }).then(res => {
          this.tpc = {
            data_type: 'Decimal',
            id: 'Power Consumption',
            max: null,
            min: null,
            name: 'Total Power Consumption',
            status: null,
            unit: 'kWh',
            value: res.data.value,
            hideChart: true
          }
          resolve(res)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
        })
      })
    },
    getAppliance () {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getAppliances', this.getSelectedLocation.id).then(res => {
          this.appliances = res.data
          if (this.appliances.length) {
            if (this.selectedApp === null || !this.appliances.filter(e => e.id === this.selectedApp).length === 0) this.selectedApp = this.appliances[0].id
          }
          resolve(this.appliances)
        }).catch(err => {
          reject(err.response.data.error)
          this.$toast.error(err.response.data.error)
        })
      })
    },
    getApplianceVariables () {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('getDataAppliance', { locationId: this.getSelectedLocation.id, applianceId: this.selectedApp }).then(res => {
          if (res.data.variables) {
            this.sensorStatus = res.data.variables
            this.lastUpdate = res.data.last_data
            if (this.selected == null) this.selected = this.sensorStatus[0].name

            const selectedVar = this.sensorStatus.find(x => x.name === this.selected)
            this.options.scales.y.min = selectedVar.min
            this.options.scales.y.max = selectedVar.max == null ? 100 : selectedVar.max
            this.options.scales.y.title.text = selectedVar.unit && selectedVar.unit !== null ? 'Value (' + selectedVar.unit + ')' : 'Value'
          } else {
            this.sensorStatus = []
          }
          resolve(res)
        }).catch(err => {
          reject(err)
          this.$toast.error(err)
        })
      })
    },
    getSensorData () {
      return new Promise((resolve, reject) => {
        const payload = {
          locationId: this.getSelectedLocation.id,
          applianceId: this.selectedApp,
          variableId: this.selected !== 'Total Power Consumption' ? this.sensorStatus.find(x => x.name === this.selected).id : null,
          params: {}
        }
        // 1st time - set recent
        if (this.firstLoad && this.activeChartFilter === 'Latest') {
          this.chartData.splice(0, this.chartData.length)
          this.chartLabels.splice(0, this.chartLabels.length)
          this.options.scales.x.title.text = 'Date & Time'
          delete this.options.scales.x.type
          delete this.options.scales.x.time
          payload.params.recent = 60
        }

        // semi set range 3/6/12/24 hours
        if (this.activeChartFilter !== 'Latest' && this.activeChartFilter !== 'Custom Date') {
          this.chartData.splice(0, this.chartData.length)
          this.chartLabels.splice(0, this.chartLabels.length)
          // const str = this.activeChartFilter.split(' ')  // = parseInt(str[0])
          let hour
          if (this.activeChartFilter === '24 Hours') hour = 24
          else if (this.activeChartFilter === '7 Days') hour = 168

          payload.params.end_date = moment().format('YYYY-MM-DD HH:mm:ss')
          payload.params.start_date = moment().subtract(hour, 'hours').format('YYYY-MM-DD HH:mm:ss')
          this.options.scales.x.type = 'time'
          this.options.scales.x.time = {
            unit: 'hour',
            max: moment(payload.params.end_date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY h:mm A'),
            min: moment(payload.params.start_date, 'YYYY-MM-DD HH:mm:ss').subtract(hour, 'hour').format('DD/MM/YY h:mm A'),
            displayFormats: {
              quarter: 'DD/MM/YY h:mm A'
            },
            tooltipFormat: 'DD/MM/YY h:mm A'
          }
        }

        // semi set custom range
        if (this.activeChartFilter === 'Custom Date') {
          if (this.selected === 'Total Power Consumption') {
            this.chartData.splice(0, this.chartData.length)
            this.chartLabels.splice(0, this.chartLabels.length)
            payload.params.end_month = this.endMonth ? this.endMonth : moment().subtract('months').format('YYYY-MM')
            payload.params.start_month = this.startMonth ? this.startMonth : moment().subtract(12, 'months').format('YYYY-MM')

            // Set options for power consumption
            this.options.scales.y.title.text = 'Value (kWh)'
            this.options.scales.x.title.text = 'Month(s)' // payload.params.start_month + ' ~ ' + payload.params.end_month
          } else {
            this.chartData.splice(0, this.chartData.length)
            this.chartLabels.splice(0, this.chartLabels.length)
            payload.params.end_date = this.endDateTime
            payload.params.start_date = this.startDateTime

            const start = moment(this.startDateTime, 'YYYY-MM-DD HH:mm:ss')
            const end = moment(this.endDateTime, 'YYYY-MM-DD HH:mm:ss')

            if (end.diff(start, 'days') === 0) {
              this.options.scales.x.type = 'time'
              this.options.scales.x.time = {
                unit: 'hour',
                max: end.format('DD/MM/YY h:mm A'),
                min: start.format('DD/MM/YY h:mm A'),
                tooltipFormat: 'DD/MM/YY h:mm A'
              }
            } else {
              this.options.scales.x.type = 'time'
              this.options.scales.x.title.text = 'Date & Time'
              this.options.scales.x.time = {
                unit: 'day',
                tooltipFormat: 'DD/MM/YY h:mm A'
              }
            }
          }
        }
        // Latest with interval
        if (!this.firstLoad && this.activeChartFilter === 'Latest') payload.params.last_update = this.lastData

        // GET THE DATA\
        const sleep = (time) => { return new Promise((resolve) => setTimeout(resolve, time)) }
        if (this.sensorStatus.length) {
          this.$store.dispatch(this.selected !== 'Total Power Consumption' ? 'getApplicationData' : 'getTotalPowerConsumptionData', payload).then(res => {
            if (this.activeChartFilter === 'Latest' && this.firstLoad) { // 1st
              this.chartData.splice(0, this.chartData.length)
              this.chartLabels.splice(0, this.chartLabels.length)
              res.data.forEach(item => {
                this.chartLabels.unshift(moment(item.timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY h:mm A'))
                this.chartData.unshift(parseFloat(item.value))
              })
              this.lastData = this.lastUpdate
              this.firstLoad = false
            } else if (this.activeChartFilter === 'Latest' && !this.firstLoad) { // interal
              if (res.data.length) {
                this.lastData = this.last_update
                const addData = async () => {
                  for (let i = 0; i < res.data.length; i++) {
                    await sleep(50)
                    this.chartData.splice(0, 1)
                    this.chartLabels.splice(0, 1)
                    this.chartLabels.push(moment(res.data[i].timestamp, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY h:mm A'))
                    this.chartData.push(parseFloat(res.data[i].value))
                  }
                }
                addData()
              }
            } else if (this.activeChartFilter !== 'Latest' && this.selected !== 'Total Power Consumption') {
              this.firstLoad = true
              this.lastData = null
              res.data.forEach(item => {
                this.chartLabels.unshift(moment(item.timestamp, 'YYYY-MM-DD HH:mm:ss'))
                this.chartData.unshift(parseFloat(item.value))
              })
            } else if (this.activeChartFilter !== 'Latest' && this.selected === 'Total Power Consumption') {
              this.firstLoad = true
              this.lastData = null
              res.data.date.forEach((item, i) => {
                this.chartLabels.push(item)
                this.chartData.push(parseFloat(res.data.value[i]))
              })
            }
            resolve(res)
          })
        } else {
          resolve('No Data')
        }
      })
    },
    async setCustomRange () {
      this.isLoading = true
      this.activeChartFilter = 'Custom Date'
      await this.getSensorData()
      this.isLoading = true
      this.$refs.closeCustomRangeDialogBtn.click()
      this.isLoading = false
    },
    async init () {
      await this.getAppliance()
      // await this.getTotalPowerConsumption()
      if (this.selected !== 'Total Power Consumption') await this.getApplianceVariables()
      if (this.activeChartFilter === 'Latest') await this.getSensorData()
      else if (this.activeChartFilter === 'Custom Date' && this.selected === 'Total Power Consumption') {
        await this.getSensorData()
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    clearInterval(this.countdownIntervalId)
  }
}
</script>
<style>
.jobs { transition: ease all 0.3s; }
</style>
