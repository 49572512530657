<template>
  <div>
    <div class="row">
      <!-- <div class="col-12 ">
        <em>
          <small class="d-none d-xl-block text-end" style="margin-top: -25px">Refresh in {{countdown}} seconds</small>
          <span  class="d-block d-xl-none text-center" >Refresh in {{countdown}} seconds</span>
        </em>
      </div> -->
      <div class="col-xl-6">
        <div style="display: flex; flex-direction: column; height: 100%">
          <!-- <div class="custom-wrapper-icons" sty>
            <div v-for="(item, i) in parameters" :key="i" class="icon_parameter" :class="item.name === selected ? 'active' : ''" @click="activateParam(i)">
              <i class="bi me-2" :class="item.image"></i>{{item.name}}
            </div>
          </div> -->
          <div class="card" style=" overflow: hidden">
            <!-- <div class="card-body">\
            </div> -->
            <div style="min-height: 400px">
            <GMap style="width: calc(100% + 4px); height: calc(100% + 4px); margin-top: -2px; margin-left: -2px" :locations="locations" :trigger="trigger !== null ? trigger : clicked" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div class="col-12 col-md-6 col-sm-6 col-lg-4 col-xl-6" v-for="(item, i) in locations" :key="i" @mouseover="trigger = item" @mouseout="trigger = null" @click="clickLoc(item)">
            <div class="card card-hover cursor-pointer" :class="clicked ? 'activeLoc' : ''"
              style="background-size: auto 50%; background-position: 90% 60%; background-repeat: no-repeat" >
              <div class="card-body">
                <div class="d-flex ">
                  <h4 class="card-title flex-grow-1"
                    style="max-width: calc(100% - 30px); text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                    {{item.name}}
                  </h4>
                  <div class="dropdown custom-dropdown mb-0" style="margin-top: -5px; margin-right: -25px">
                    <div class="btn sharp tp-btn dark-btn px-0" data-bs-toggle="dropdown">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </div>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button class="dropdown-item" @click="goTo('/environments', item.id)"><i class="bi bi-pie-chart-fill me-2"></i>Environments</button>
                      <button class="dropdown-item" @click="goTo('/sensor', item.id)"><i class="bi bi-pie-chart-fill me-2"></i>Appliances</button>
                      <button class="dropdown-item" @click="goTo('/system', item.id)"><i class="bi bi-info-circle-fill me-2"></i>System</button>
                      <!-- <button class="dropdown-item" @click="goTo('/photos/gallery', item.id)"><i class="bi bi-card-image me-2" ></i>Photo</button> -->
                      <button class="dropdown-item" @click="goTo('/logs/list', item.id)"><i class="bi bi-sliders me-2" ></i>Logs</button>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center" style="min-height: 100px">
                  <p class="text-center align-self-center">
                    <span v-if="selected === 'System'" >
                      <small class="text-muted">SWITCH</small>
                      <h1 :ref="'detailsInfo' + i" :class="item.status === 'On' ? 'text-primary' : 'text-danger'" style="text-transform: uppercase; line-height: 0.8em; font-size: 36px"><strong>{{ item.status }}</strong></h1>
                    </span>
                    <span v-if="selected === 'Consumption'">
                      <h1 class=" mb-0" :ref="'detailsInfo' + i"><strong class="text-blue" style="text-transform: uppercase; line-height: 0.8em; font-size: 36px">{{ item.total_consumption }}</strong></h1>
                      <small class="text-muted" style="margin-top: -10px; display: block;">kWh</small>
                    </span>
                    <span v-if="selected === 'Total Active Energy'">
                      <h1 class=" mb-0" :ref="'detailsInfo' + i"><strong class="text-blue" style="text-transform: uppercase; line-height: 0.8em; font-size: 36px">{{ item.total_active_energy }}</strong></h1>
                      <small class="text-muted" style="margin-top: -10px; display: block;">kWh</small>
                    </span>
                    <span v-if="selected === 'Bill'">
                      <small class="text-muted" >RM</small>
                      <h1 class=" mb-0" :ref="'detailsInfo' + i">
                        <strong class="text-green" style="text-transform: uppercase; line-height: 0.8em; font-size: 36px">
                          {{ item.total_estimated }}
                        </strong>
                      </h1>
                    </span>
                    </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <a @click="toastrr">Toastr</a> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
/* import IconWater from '@/assets/parameters/icon_water.png'
import IconTemP from '@/assets/parameters/icon_temp.png'
import IconPH from '@/assets/parameters/icon_ph.png' */
/* import IconEC from '@/assets/parameters/icon_ec.png'
import IconP from '@/assets/parameters/icon_p.png'
import IconN from '@/assets/parameters/icon_n.png'
import IconK from '@/assets/parameters/icon_k.png' */
import WeatherRainy from '@/assets/weather/animation_rain.gif'
import WeatherSunny from '@/assets/weather/animation_sun.gif'
import WeatherCloudy from '@/assets/weather/animation_cloudy.gif'
// import Chart from '@/components/Templates/ChartSensor'
import GMap from '@/components/GMap'

export default {
  name: 'dashboard',
  components: {
    // Chart,
    GMap
  },
  data () {
    return {
      WeatherRainy: WeatherRainy,
      WeatherSunny: WeatherSunny,
      WeatherCloudy: WeatherCloudy,
      parameters: [
        { text: 'System Status', name: 'System', image: 'bi-gear-fill', active: true }
        /* { text: 'Consumption', name: 'Consumption', image: 'bi-lightning-charge-fill', active: false },
        { text: 'Total Active Energy', name: 'Total Active Energy', image: 'bi-lightning-charge', active: false },
        { text: 'Billing', name: 'Bill', image: 'bi-file-bar-graph-fill', active: false } */
      ],
      locations: [],
      locationList: [],
      selected: null,
      trigger: null,
      isLoading: false,
      clicked: null,

      // set interval
      intervalId: '',
      countdown: 0,
      countdownIntervalId: ''
    }
  },
  created () {
    /* Page Title */
    this.$emit('pageTitle', 'Dashboard')
    if (this.selected === null) this.selected = this.parameters[0].name
    this.getLocations()
  },
  watch: {
    selected (val) {
      clearInterval(this.intervalId)
      clearInterval(this.countdownIntervalId)
      console.log('selected', val)
      if (val !== null) {
        this.getLiveData(val)
        this.countDown()
        this.intervalId = setInterval(function () {
          this.countDown()
          this.getLiveData(val)
        }.bind(this), this.$config.apiRequestInterval)
      }
    }
  },
  methods: {
    clickLoc (val) {
      if (this.clicked === null) {
        this.clicked = val
      } else this.clicked = null
    },
    goTo (path, locationId) {
      const activeLocation = this.locationList.find(list => list.id === parseFloat(locationId))
      this.$store.dispatch('locations/selectedLocation', activeLocation).then(() => {
        this.$router.push(path)
      })
    },
    getLocations (val) {
      this.$store.dispatch('locations/getLocations').then(res => {
        console.log(res.data)
        this.locationList = res.data
      })
    },
    countDown () {
      clearInterval(this.countdownIntervalId)
      this.countdown = this.$config.apiRequestInterval / 1000
      this.countdownIntervalId = setInterval(function () {
        this.countdown = this.countdown - 1
        // console.log(this.countdown)
      }.bind(this), 1000)
    },
    getLiveData (val) {
      let payload = ''

      if (val === 'System') payload = '/dashboard/system-status'
      /*  if (val === 'Consumption') payload = '/dashboard/consumption'
      if (val === 'Bill') payload = '/dashboard/bill'
      if (val === 'Total Active Energy') payload = '/dashboard/active-energy' */

      this.isLoading = true
      this.locations.forEach((ex, i) => {
        this.loading(this.$refs['detailsInfo' + i], 'show')
      })
      this.$store.dispatch('getLiveData', payload).then(res => {
        this.locations = res.data
        this.isLoading = false
        this.locations.forEach((ex, i) => {
          this.loading(this.$refs['detailsInfo' + i], 'hide')
        })
      }).catch(err => {
        console.log(err)
        this.isLoading = false
        this.locations.forEach((ex, i) => {
          this.loading(this.$refs['detailsInfo' + i], 'hide')
        })
      })
    },
    activateParam (i) {
      this.selected = this.parameters[i].name
      console.log(this.selected)
    }
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
    clearInterval(this.countdownIntervalId)
  }
}
</script>
<style>
.card-hover {
  border: 1px solid rgba(0,0,0,0)
}
.card-hover:hover, .card-hover.activeLoc {
  border: 1px solid var(--primary)
}
</style>
