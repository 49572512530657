<template>
  <div>
    <div class="row">
      <div class="col-xl-12  col-md-12">
        <div v-for="(item, i) in valueA" :key="i + 'main'" class="mb-3">
          <div class="input-group mb-2">
            <span class="input-group-text bg-dark text-white" style="min-width: 50px">
              <strong>{{i + 1}}</strong>
            </span>
             <span class="input-group-text d-none d-lg-block" >Appliance Name</span>
            <span class="input-group-text form-control bg-white" style="justify-content: flex-start;">{{ item.name }}</span>
            <span class="input-group-text bg-danger p-0" v-if="editMode">
              <button class="btn btn-sm tp-btn btn-rounded text-white" @click="$emit('deleteAppliances', i)">
                <i class="fas fa-times"></i>
              </button>
            </span>
            <span class="input-group-text bg-primary p-0" v-if="editMode">
              <button class="btn btn-sm tp-btn btn-rounded text-white" @click="edit(i)" ref="addDialogBtn"
                data-bs-toggle="modal" data-bs-target="#addDialog">
                <i class="fas fa-edit" v-if="editMode"></i>
                <i class="fas fa-view" v-else></i>
              </button>
            </span>

          </div>
        </div>
        <div v-if="!valueA.length" class="text-center p-3 text-light form-control mb-3"><em>No Appliance Added</em></div>
      </div>
      <div class="col-md-12 text-center" v-if="editMode && 11 > valueA.length ">
        <button class="btn btn-outline-primary m-auto" @click="addNewDialog = true" ref="addDialogBtn"
          data-bs-toggle="modal" data-bs-target="#addDialog">
          <i class="fas  fa-plus me-2"></i>
          Add Appliance
        </button>
      </div>
    </div>

    <!--Dialog-->
    <Dialog :id="'addDialog'" @close="addNewDialog = false, clearAfterAdd()" centered large title="Add Appliance">
      <template slot="content">
        <div class="row">
          <div class="col-md-12 px-3 pb-3">
            <label class="form-label font-w600">Appliance Name<span class="text-danger scale5 ms-2">
                *</span></label>
            <input v-model="form.name" :disabled="!editMode" class="form-control px-2" />
          </div>
          <div class="col-md-12 pt-3">
            <label class="form-label font-w600">Switch Control</label>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-xl-12">
                <label class="radio-inline me-3"><input v-model="form.controlSwitch.mode" :value="1" type="radio" :disabled="!editMode" /> Manual</label>
                <label class="radio-inline me-3"><input v-model="form.controlSwitch.mode" :value="2" type="radio" :disabled="!editMode" /> Days</label>
                <label class="radio-inline me-3"><input v-model="form.controlSwitch.mode" :value="3" type="radio" :disabled="!editMode" /> Sensor</label>
              </div>
              <div class="col-xl-12 pb-3" v-if="form.controlSwitch.mode == 1">
                <div class="p-3 border rounded" style="background-color: #eee">
                  <label class="form-label font-w600">Manual Switch</label>
                  <select class="form-select form-control" v-model="manual_switch" :disabled="!editMode" >
                    <option :value="false" >Switch OFF</option>
                    <option :value="true" >Switch ON</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-12 pb-3" v-if="form.controlSwitch.mode == 2">
                <div class="p-3 border rounded" style="background-color: #eee">
                  <div class="input-group mb-4">
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.monday" >
                      <span class="mt-1">Monday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.tuesday" >
                      <span class="mt-1">Tuesday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.wednesday" >
                      <span class="mt-1">Wednesday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.thursday" >
                      <span class="mt-1">Thursday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.friday" >
                      <span class="mt-1">Friday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.saturday" >
                      <span class="mt-1">Saturday</span>
                    </span>
                    <span class="input-group-text d-flex align-items-center justify-content-start flex-fill">
                      <input type="checkbox" :disabled="!editMode" class="form-check-input mt-0 me-2" v-model="days.sunday" >
                      <span class="mt-1">Sunday</span>
                    </span>
                  </div>
                  <div v-if="editMode">
                    <em class="mb-2 d-block text-black-100" >
                      fill in information below than click <span class="mx-1"><i class="fa fa-plus"></i></span> to add new time range
                    </em>
                    <div class="input-group mb-2" >
                      <span class="input-group-text bg-primary text-white " style="min-width: 50px"><strong>ADD</strong></span>
                      <span class="input-group-text"><strong>Time On</strong></span>
                      <vue-timepicker placeholder="Time On" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="timesForm.time_on"></vue-timepicker>
                      <span class="input-group-text"><strong>Time Off</strong></span>
                      <vue-timepicker placeholder="Time Off" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="timesForm.time_off"></vue-timepicker>
                      <span class="input-group-text py-0 bg-primary">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addTime()" :disabled="!timesForm.time_on || !timesForm.time_off"><i class="fa fa-plus"></i></button>
                      </span>
                    </div>
                  </div>
                  <div class="input-group mb-2" v-for="(el, index) in times" :key="index + 'sub'">
                    <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index + 1}}</strong></span>
                    <span class="input-group-text"><strong>Time On</strong></span>
                    <vue-timepicker :disabled="!editMode" placeholder="Time On" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="el.time_on"></vue-timepicker>
                    <span class="input-group-text"><strong>Time Off</strong></span>
                    <vue-timepicker :disabled="!editMode" placeholder="Time Off" format="h:mm A" class="form-control p-0 border-0" input-class=" timepicker-form-control" v-model="el.time_off"></vue-timepicker>
                    <span class="input-group-text bg-danger py-0" v-if="editMode">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteTime(i, index)"><i class="fas fa-times"></i></button>
                    </span>
                  </div>
                  <div v-if="!times.length" class="text-center p-3 text-light form-control"><em>No Time Added</em></div>
                </div>
              </div>
              <div class="col-xl-12 pb-3" v-if="form.controlSwitch.mode == 3">
                <div class="p-3 border rounded" style="background-color: #eee">
                <label class="form-label font-w600">Sensor Name</label><br />
                <label class="radio-inline me-3"><input v-model="sensor.variable_name" :disabled="!editMode" value="Humidity" type="radio" /> Humidity</label>
                <label class="radio-inline me-3"><input v-model="sensor.variable_name" :disabled="!editMode" value="Temperature" type="radio" /> Temperature</label>
                <div class="input-group mb-2" >
                  <span class="input-group-text">Turn<strong class="mx-1">ON</strong>when {{sensor.variable_name}} greater than</span>
                  <input type="number" v-model="sensor.greater_than" :disabled="!editMode" placeholder="" class="form-control px-2"  />
                  <span class="input-group-text">Turn<strong class="mx-1">OFF</strong>when {{sensor.variable_name}} less than</span>
                  <input type="number" v-model="sensor.less_than" :disabled="!editMode" placeholder="" class="form-control px-2"  />
                </div>

                <!--Add Environment-->
                <div class="col-md-6 pt-3">
                  <label class="form-label font-w600">Environment</label>
                  <select
                    class="form-select form-control"
                    v-model="sensor.environment_index"
                    :disabled="!editMode"
                  >
                  <option :value="i" v-for="(item, i) in envList" :key="i + 'envList'" >{{item.name}}</option>
                  <option :value="null" >Average</option>
                </select>

                </div>
              </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 pt-3">
            <label class="form-label font-w600">Variables</label>
          </div>
          <div class="col-md-12 pb-2" v-for="(item, i) in form.variables" :key="i">
            <div class="p-3 border rounded" style="background-color: #eee">
              <h4>{{item.name}}</h4>
              <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-6 py-3">
                  <label class="form-label font-w600">Status<span class="text-danger scale5 ms-2"> *</span></label>
                  <div class="pb-2">
                    <select class="form-select form-control" v-model="item.status" :disabled="!editMode">
                      <option :value="false" >Disable</option>
                      <option :value="true">Enable</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 py-3">
                  <label class="form-label font-w600">Data Type</label>
                  <div class="pb-2">
                    <select class="form-control" v-model="item.data_type" :disabled="true">
                      <option v-for="(list, index) in types" :value="list" :key="index">{{list}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3 py-3" v-if="item.data_type == 'Decimal'">
                  <label class="form-label font-w600">Decimal</label>
                  <input type="number"  :disabled="true" v-model="item.decimal"
                    placeholder="" class="form-control ">
                </div>
                <div class="col-xl-2 col-lg-3 col-md-3 py-3">
                  <label class="form-label font-w600">Visibility</label>
                  <div class="pb-2">
                    <select class="form-control form-select" v-model="item.visibility" :disabled="!editMode">
                      <option :value="true" >Show</option>
                      <option :value="false" >Hide</option>
                    </select>
                  </div>
                </div>

                <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3 py-3">
                  <label class="form-label font-w600">Unit</label>
                  <input type="text" v-model="item.unit"  :disabled="true"
                    placeholder="" class="form-control ">
                </div>
              </div>
              <div class="row" v-if="item.data_type !== 'Text'">
                <div class="col-12 py-3 d-none d-lg-block">
                  <label class="form-label font-w600">Ranges</label>
                  <div class="input-group mb-2" v-if="ranges.length && item.data_type === 'Decimal' && editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Min</span>
                    <input type="number" class="form-control" v-model="ranges[i].min" >
                    <span class="input-group-text">Max</span>
                    <input type="number" class="form-control" v-model="ranges[i].max" >
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].min || !ranges[i].max || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>
                  <div class="input-group mb-2" v-if="ranges.length && item.data_type === 'Boolean' && editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Value</span>
                    <select class="form-select form-control" v-model="ranges[i].value">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].value || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>

                  <template v-if="item.data_type === 'Decimal'">
                    <div class="input-group mb-2"
                      :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                      v-for="(rangeList, index) in item.ranges" :key="i + index + 'rga'">
                      <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index +
                          1}}</strong></span>
                      <span class="input-group-text">Min</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="item.ranges[index].min">
                      <span class="input-group-text">Max</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.max">
                      <span class="input-group-text">Status</span>
                      <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                        <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                        <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                      </select>
                      <span class="input-group-text bg-danger p-0" v-if="editMode">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                            class="fas fa-times"></i></button>
                      </span>
                    </div>
                  </template>
                  <template v-else-if="item.data_type === 'Boolean'">
                    <div class="input-group mb-2"
                      :class="!rangeList.value || !rangeList.status ? 'input-danger-o' : ''"
                      v-for="(rangeList, index) in item.ranges" :key="index + 'lg'">
                      <span class="input-group-text">Value</span>
                      <select class="form-select form-control" v-model="rangeList.value" :disabled="!editMode">
                        <option :selected="rangeList.status == null ? true : false" :value="null">Choose...</option>
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                      <span class="input-group-text">Status</span>
                      <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                        <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                        <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                      </select>
                      <span class="input-group-text bg-danger p-0" v-if="editMode">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                            class="fas fa-times"></i></button>
                      </span>
                    </div>
                  </template>
                  <div v-if="!item.ranges.length" class="text-center p-3 text-light form-control"><em>No Range Added</em>
                  </div>
                </div>
                <div class="col-12 py-3 d-block d-lg-none">
                  <label class="form-label font-w600">Ranges</label>
                  <div class="input-group mb-1" v-if="ranges.length && item.data_type === 'Decimal' && editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Min</span>
                    <input type="number" class="form-control" v-model="ranges[i].min" >
                    <span class="input-group-text">Max</span>
                    <input type="number" class="form-control" v-model="ranges[i].max" >
                  </div>
                  <div class="input-group mb-3" v-if="ranges.length && item.data_type === 'Decimal' && editMode">
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].min || !ranges[i].max || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>
                  <div class="input-group mb-1" v-if="ranges.length && item.data_type === 'Boolean' && editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Value</span>
                    <select class="form-select form-control" v-model="ranges[i].value">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option value="false">False</option>
                      <option value="true">True</option>
                    </select>
                  </div>
                  <div class="input-group mb-3" v-if="ranges.length && item.data_type === 'Boolean' && editMode">
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text p-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].value || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>

                  <template v-if="item.data_type === 'Decimal'">
                    <template v-for="(rangeList, index) in item.ranges">
                      <div class="input-group mb-1" :key="0 + i + index + 'rgx'"
                        :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                        >
                        <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index +
                            1}}</strong></span>
                        <span class="input-group-text">Min</span>
                        <input type="number" :disabled="!editMode" class="form-control" v-model="item.ranges[index].min">
                        <span class="input-group-text">Max</span>
                        <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.max" />
                      </div>
                      <div class="input-group mb-3" :key="0 + i + index + 'rgz'"
                        :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                        >
                        <span class="input-group-text">Status</span>
                        <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                          <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                          <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                        </select>
                        <span class="input-group-text bg-danger p-0" v-if="editMode">
                          <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                              class="fas fa-times"></i></button>
                        </span>
                      </div>
                    </template>
                  </template>
                  <template v-else-if="item.data_type === 'Boolean'" v-for="(rangeList, index) in item.ranges" >
                    <div class="input-group mb-1"
                      :class="!rangeList.value || !rangeList.status ? 'input-danger-o' : ''"
                      :key="index + 'lgx'"
                      >
                      <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index +
                            1}}</strong></span>
                      <span class="input-group-text">Value</span>
                      <select class="form-select form-control" v-model="rangeList.value" :disabled="!editMode">
                        <option :selected="rangeList.status == null ? true : false" :value="null">Choose...</option>
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </select>
                    </div>
                    <div class="input-group mb-3"
                    :key="index + 'lgz'"
                      :class="!rangeList.value || !rangeList.status ? 'input-danger-o' : ''"
                      >
                      <span class="input-group-text">Status</span>
                      <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                        <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                        <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                      </select>
                      <span class="input-group-text bg-danger p-0" v-if="editMode">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                            class="fas fa-times"></i></button>
                      </span>
                    </div>
                  </template>
                  <div v-if="!item.ranges.length" class="text-center p-3 text-light form-control"><em>No Range Added</em>
                  </div>
                </div>
              </div>
              <div class="row" v-if="item.data_type == 'Decimal'">
                <div class="col-xl-12 py-3">
                  <label class="form-label font-w600">Threshold Notification</label>
                  <div class="input-group me-2">
                    <span class="input-group-text d-flex align-items-center">
                      <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="item.thresholdNotification.trigMin">
                      <span class="mt-1">Min</span>
                    </span>
                    <input type="number" v-model="item.thresholdNotification.min" :disabled="!editMode" placeholder="" class="form-control px-2" >
                    <span class="input-group-text d-flex align-items-center">
                      <input type="checkbox" class="form-check-input mt-0 me-2" :disabled="!editMode" v-model="item.thresholdNotification.trigMax" >
                      <span class="mt-1">Max</span>
                    </span>
                    <input type="number" v-model="item.thresholdNotification.max" :disabled="!editMode" placeholder="" class="form-control px-2" >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <a class="btn btn-danger btn-sm  light" ref="closeDialogBtn" data-bs-toggle="modal" @click="clearAfterAdd()"
          data-bs-target="#addDialog">Cancel</a>
        <a class="btn btn-primary btn-sm" :disabled="!this.form.name" @click="addAppliance" v-if="this.selected == null && editMode">Add Appliance</a>
        <a class="btn btn-primary btn-sm" :disabled="!this.form.name" @click="updateAppliance" v-if="this.selected !== null && editMode">Update Appliance</a>
      </template>
    </Dialog>

  </div>
</template>
<script>
import Dialog from '@/components/Templates/Dialog'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
export default {
  props: ['valueA', 'editMode', 'envList'],
  components: {
    Dialog,
    VueTimepicker
  },
  data () {
    return {
      editmode: true,
      addNewDialog: false,
      editDialog: false,
      selected: null,
      statusRange: ['Normal', 'Warning', 'Alert'],
      types: ['Number', 'Decimal', 'Boolean', 'Text'],
      ranges: [],
      timesForm: {
        time_on: '',
        time_off: ''
      },
      form: {
        name: '',
        icon: '',
        variables: [
          {
            name: 'Voltage',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'V',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Current',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'I',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Active Power',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'kW',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Load Relay Status',
            data_type: 'Boolean',
            status: true,
            unit: null,
            visibility: true,
            ranges: []
          }
          /* {
            name: 'Power Factor',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'PF',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Frequency',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'Hz',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Total Active Energy',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'kWh',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          } */
        ],
        controlSwitch: {
          mode: 1
        }
      },
      days: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      },
      times: [],
      manual_switch: false,
      sensor: {
        variable_name: '',
        less_than: 0,
        greater_than: 0,
        environment_index: null
      },

      // templates data

      daysDefault: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.ranges = []
    this.populateRanges()
  },
  watch: {
  },
  methods: {
    clearAfterAdd () {
      this.days = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      this.form = {
        name: '',
        icon: '',
        variables: [
          {
            name: 'Voltage',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'V',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Current',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'I',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Active Power',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'W',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Load Relay Status',
            data_type: 'Boolean',
            status: true,
            visibility: true,
            unit: null,
            ranges: []
          }
          /* {
            name: 'Power Factor',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'PF',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Frequency',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: true,
            unit: 'Hz',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          },
          {
            name: 'Total Active Energy',
            data_type: 'Decimal',
            decimal: 1,
            status: true,
            visibility: false,
            unit: 'kWh',
            ranges: [],
            thresholdNotification: {
              trigMin: false,
              trigMax: false,
              min: 0,
              max: 0
            }
          } */
        ],
        controlSwitch: {
          mode: 1
        }
      }
      this.times = []
      this.ranges = []
      this.manual_switch = false
      this.sensor = { variable_name: '', less_than: 0, greater_than: 0, environment_index: null }
      this.populateRanges()
    },
    populateRanges () {
      this.form.variables.forEach(el => {
        if (el.data_type === 'Decimal') {
          this.ranges.push({ min: 0, max: 0, status: null })
        } else if (el.data_type === 'Boolean') {
          this.ranges.push({ value: '', status: null })
        }
      })
    },
    deleteAppliance (i) {
      this.$emit('deleteAppliance', i)
    },
    addRange (i) {
      // this.form.variables[i].ranges.push(payload)
      // console.log(this.form.variables[i].ranges)
      if (this.form.variables[i].data_type === 'Decimal') {
        const payload = {
          min: this.ranges[i].min,
          max: this.ranges[i].max,
          status: this.ranges[i].status
        }
        this.form.variables[i].ranges.push(payload)
        this.ranges[i].min = 0
        this.ranges[i].max = 0
        this.ranges[i].status = null
      } else if (this.form.variables[i].data_type === 'Boolean') {
        const payload = {
          value: this.ranges[i].value,
          status: this.ranges[i].status
        }
        this.form.variables[i].ranges.push(payload)
        this.ranges[i].value = 0
        this.ranges[i].status = null
      }
    },
    addTime () {
      this.times.push({
        time_on: this.timesForm.time_on,
        time_off: this.timesForm.time_off
      })
      console.log(this.times)
    },
    /*eslint-disable */
    addAppliance () {
      if (this.form.name) {
        const payload = this.form

        if (payload.controlSwitch.mode == 1) {
          payload.controlSwitch.manual_switch = this.manual_switch
        } else if (payload.controlSwitch.mode == 2) {
          payload.controlSwitch.days = this.days
          payload.controlSwitch.times = this.times
        } else if (payload.controlSwitch.mode == 3) {
          payload.controlSwitch.sensor = this.sensor
        }
        this.$emit('addAppliances', payload)
        this.$refs.closeDialogBtn.click()
        this.clearAfterAdd()
      }
    },
    updateAppliance () {
      if (this.form.name) {
        console.log(this.sensor)
        const payload = {
          index: this.selected,
          data: this.form
        }
        if (payload.data.controlSwitch.mode == 1) {
          payload.data.controlSwitch.manual_switch = this.manual_switch
        } else if (payload.data.controlSwitch.mode == 2) {
          payload.data.controlSwitch.days = this.days
          payload.data.controlSwitch.times = this.times
        } else if (payload.data.controlSwitch.mode == 3) {
          payload.data.controlSwitch.sensor = this.sensor
        }

        // payload.data.variables[6].visibility = true
        this.$emit('updateAppliances', payload)
        this.$refs.closeDialogBtn.click()
        this.clearAfterAdd()
        this.selected = null
      }
    },
    edit (i) {
      this.selected = i
      
      if (this.valueA[i].id) this.form.id = this.valueA[i].id
      this.form.name = this.valueA[i].name
      this.form.icon = this.valueA[i].icon
      this.form.variables = this.valueA[i].variables
      this.form.controlSwitch.mode = this.valueA[i].controlSwitch.mode

      if (this.valueA[i].controlSwitch.mode == 1) this.manual_switch = this.valueA[i].controlSwitch.manual_switch
      if (this.valueA[i].controlSwitch.mode == 2) {
        this.days = this.valueA[i].controlSwitch.days
        this.times = this.valueA[i].controlSwitch.times
      }
      if (this.valueA[i].controlSwitch.mode == 3) this.sensor = this.valueA[i].controlSwitch.sensor

      console.log(this.valueA[i].controlSwitch.sensor, this.sensor)

      this.addNewDialog = true
    },
    deleteApplianceRange (i, index) {
      this.form.variables[i].ranges.splice(index, 1)
    }
  }
}
</script>

<style></style>
