import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import Global from './modules/global.js'
import User from './modules/user.js'
import Config from './modules/config.js'
import Api from './modules/api.js'
import Rtu from './modules/rtu.js'
import Locations from './modules/locations.js'
import Gallery from './modules/gallery.js'
import Logs from './modules/logs.js'
import System from './modules/system.js'
import Environments from './modules/environments.js'
import Bill from './modules/bill.js'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'storage', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  reducer: (state) => ({
    user: {
      data: state.user.data,
      token: state.user.token
    },
    config: {
      theme: state.config.theme
    }
  })
})

const store = new Vuex.Store({
  modules: {
    global: Global,
    user: User,
    config: Config,
    api: Api,
    rtu: Rtu,
    locations: Locations,
    gallery: Gallery,
    logs: Logs,
    system: System,
    bill: Bill,
    environment: Environments
  },
  plugins: [vuexLocalStorage.plugin]
})

export default store
