<template>
  <PageTemplate :hideTitle="true">
    <template slot="header-buttons">
    </template>
    <template slot="content">
      <div :class="isMobileView ? '' : 'd-flex'">
        <div :class="isMobileView ? '' : 'me-4 mb-2'" :style="{width: isMobileView ? '100%' : '320px'}">
          <LocationSelection />
          <h5>Filters</h5>
          <div class="card mt-2"  style="height: auto" >
            <div class="card-body">
              <div>
                <div class="">
                  <label  class="form-label font-w600">Periodical</label>
                  <div>
                    <label class="radio-inline me-3"><input v-model="search.type" value="days" type="radio" /> Days</label>
                    <label class="radio-inline me-3"><input v-model="search.type" value="months" type="radio" /> Months</label>
                  </div>
                </div>
                <div class="mt-4">
                  <label  class="form-label font-w600" v-if="search.type == 'days'">Month & Year</label>
                  <label  class="form-label font-w600" v-else>Year</label>
                  <div class="input-group mb-2"  style="flex-wrap: nowrap;">
                    <span class="input-group-text "><i class="bi bi-calendar"></i></span>
                    <date-picker v-model="search.monthYear" :type="search.type == 'days' ? 'month' : 'year'" valueType="format" :format="search.type == 'days' ? 'MM/YYYY' : 'YYYY'"></date-picker>

                    <!-- <month-picker-input :no-default="true" v-model="search.monthYear" year-only @change-year="test()" ></month-picker-input> -->

                    <!-- <VueDatePicker
                      noHeader
                      no-calendar-icon
                      class="custom-datepicker form-control"
                      :type="search.type == 'days' ? 'month' : 'year'"
                      :format="search.type == 'days' ? 'MM/YYYY' : 'YYYY'"
                      placeholder=""
                      v-model="search.monthYear" /> -->
                    <span
                      @click="search.monthYear = null"
                      class="input-group-text bg-white cursor-pointer"
                      style="border: 1px solid #ccc">
                      <span class="text-danger" ><i class="bi bi-arrow-clockwise" style="font-size: 16px"></i></span>
                    </span>
                  </div>
                </div>
                <div class="mt-4">
                  <label  class="form-label font-w600">Via</label>
                  <div class="d-flex">
                    <div class="form-check me-3">
                      <input class="form-check-input" type="checkbox" id="APPCheck" v-model="search.viaAPP">
                      <label class="form-check-label" for="APPCheck">
                        App
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="RTUCheck" v-model="search.viaRTU">
                      <label class="form-check-label" for="RTUCheck">
                        RTU
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)', maxWidth: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card"  ref="detailsWrapper">
            <div class="card-header" >
              <h4 class="fs-20">Alarm Logs Summary</h4>
            </div>
            <div class="card-body">
              <BarChart :chartData="chartData" :chartLabels="chartLabels" :options="options" v-if="search.monthYear" label="Total Logs" />
              <div v-else class="text-center align-items-center justify-content-center d-flex m-auto" style="height: 100%">
                <em class="mb-5">{{'No Data Available. Please select ' + (search.type == 'days' ? 'month & year.' : 'year')}}</em>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import LocationSelection from '@/components/LocationSelection'
import PageTemplate from '@/components/Templates/PageTemplate'
import BarChart from '@/components/Templates/ChartBar'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import moment from 'moment'
export default {
  name: 'Logs',
  components: {
    PageTemplate,
    LocationSelection,
    BarChart,
    DatePicker

  },
  filters: {
  },
  data () {
    return {
      search: {
        type: 'days',
        viaRTU: true,
        viaAPP: true,
        monthYear: ''
      },

      chartLabels: [],
      chartData: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            ticks: {
              beginAtZero: true
            }
          }
        },
        onClick: this.checkDetails
      },
      logs: null
    }
  },
  created () {
    this.search.monthYear = moment(new Date()).format('MM/YYYY')
    this.$emit('pageTitle', 'Logs')
  },
  watch: {
    getSelectedLocation: {
      deep: true,
      handler (val) {
        if (this.search.monthYear !== null) this.searchLogs(this.search)
      }
    },
    'search.type' (val) {
      this.search.monthYear = null
    },
    'search.viaRTU' (val) {
      if (this.search.monthYear !== null) this.searchLogs(this.search)
    },
    'search.viaAPP' (val) {
      if (this.search.monthYear !== null) this.searchLogs(this.search)
    },
    'search.monthYear' (val) {
      if (val !== null) this.searchLogs(this.search)
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    }
  },
  methods: {
    checkDetails (point, event) {
      if (this.search.type === 'days') {
        const date = this.logs[event[0]._index].date_raw
        this.$router.push({ path: '/logs/list', query: { startDate: date, endDate: date } })
      } else if (this.search.type === 'months') {
        const start = this.search.monthYear + moment(this.logs[event[0]._index].month, 'D').format('-DD-01')
        const end = moment(start).endOf('month').format('YYYY-MM-DD')
        this.$router.push({ path: '/logs/list', query: { startDate: start, endDate: end } })
      }
    },
    test () {
      console.log(this.search.monthYear)
    },
    searchLogs (val, page) {
      this.chartData.splice(0, this.chartData.length)
      this.chartLabels.splice(0, this.chartLabels.length)
      const payload = {
        location_id: this.getSelectedLocation.id
      }

      if (val.type === 'days') payload.dailyDate = moment(val.monthYear, 'MM/YYYY', true).isValid() ? moment(val.monthYear, 'MM/YYYY').format('YYYY-MM') : moment(val.monthYear).format('YYYY-MM')
      else if (val.type === 'months') payload.monthlyDate = val.monthYear
      if ((!val.viaRTU && val.viaAPP) || (val.viaRTU && !val.viaAPP)) payload.via = val.viaRTU ? 'RTU' : 'App'
      if (val.monthYear !== null) {
        this.loading(this.$refs.detailsWrapper, 'show')
        this.$store.dispatch('getLogsSummary', payload).then(res => {
          this.logs = res.data.logs
          res.data.total.forEach((item, i) => {
            this.chartData.push((typeof item === 'string' ? parseFloat(item) : item))
            this.chartLabels.push(res.data.date[i])
          })
          this.loading(this.$refs.detailsWrapper, 'hide')
        }).catch(err => {
          this.$toast.error(err.response.data.error)
          this.loading(this.$refs.detailsWrapper, 'hide')
        })
      }
      /* if (val.dateRange.startDate !== null || val.dateRange.endDate !== null) {
        payload.start = moment(val.dateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00'
        payload.end = moment(val.dateRange.endDate).format('YYYY-MM-DD') + ' 00:00:00'
      }
      if ((!val.viaRTU && val.viaAPP) || (val.viaRTU && !val.viaAPP)) payload.via = val.viaRTU ? 'RTU' : 'App'
      if (page) payload.url = page

      this.loading(this.$refs.detailsWrapper, 'show')
      this.$store.dispatch('getLogs', payload).then(res => {
        this.dataTable = res.data.logs
        this.logInfo = res.data.pagination
        this.loading(this.$refs.detailsWrapper, 'hide')
      }).catch(err => {
        this.$toast.error(err.response.data.error)
        this.loading(this.$refs.detailsWrapper, 'hide')
      }) */
    }
  }
}
</script>
