<template>
  <div class="card" style="height: auto" v-if="locations.length">
    <div class="card-body p-3">
      <div class="d-flex mb-2">
        <div class="location-img-wrapper d-flex align-items-center justify-content-center">
          <img :src="getSelectedLocation ? getSelectedLocation.default_photo_url : ''" v-if="getSelectedLocation.default_photo_url" />
          <i v-else class="bi bi-image d-block" style="font-size: 40px"></i>
        </div>
        <div class="mt-2 flex-grow-1">
          <h5 class="">{{getSelectedLocation.name}}</h5>
          <div>{{getSelectedLocation.lat}}° N, {{getSelectedLocation.lng}}° E</div>
        </div>
      </div>
      <!-- <select class="form-select" v-model="selected" style="font-size: 12px; height: auto; white-space: normal;">
        <option v-for="(item, i) in locations" :key="item.id + i" :value="item.id">
          {{item.address}}
          </option>
      </select> -->
        <Select2 class="custom-select2" v-model="selected" :options="myOptions" />
    </div>
  </div>
</template>

<script>
// import Select2Component
import Select2 from 'v-select2-component'
export default {
  props: ['refreshLocation'],
  components: {
    Select2
  },
  data () {
    return {
      locations: [],
      selected: null,
      name: '',
      myValue: ''
      // myOptions: [{ id: 1, text: 'value' }, { id: 2, text: 'value2' }]
    }
  },
  created () {
    // this.$store.dispatch('locations/clearSelectedLocation')
    this.getLocations()
  },
  watch: {
    refreshLocation (val) {
      this.getLocations('update')
    },
    selected (val) {
      const activeLocation = this.locations.find(list => list.id === parseFloat(val))
      this.$store.dispatch('locations/selectedLocation', activeLocation)
    }
  },
  methods: {
    getLocations (val) {
      this.$store.dispatch('locations/getLocations').then(res => {
        this.locations = res.data
        if (this.locations.length) {
          if (this.getSelectedLocation == null) {
            this.$store.dispatch('locations/selectedLocation', this.locations[0])
            this.selected = this.locations[0].id
          } else if (val === 'update') {
            const activeLocation = this.locations.find(list => list.id === parseFloat(this.getSelectedLocation.id))
            this.$store.dispatch('locations/selectedLocation', activeLocation)
          } else {
            this.selected = this.getSelectedLocation.id
          }
        }
      })
    }
  },
  computed: {
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    },
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    myOptions () {
      const list = []
      this.locations.forEach(el => {
        list.push({
          text: el.name + ' - ' + el.address,
          id: el.id
        })
      })
      return list
    }
  }
}
</script>
<style>
.location-img-wrapper {
    width: 80px;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    margin: auto;
    min-width: 80px;
    margin-right: 15px;
    overflow: hidden;
    position: relative;
}
.location-img-wrapper img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200%;
  max-height: 200%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  transition: ease all 0.3s;
}
.custom-select2 .select2-selection{
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
  background-color: #fff;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  border: 1px solid #dddddd;
  border-radius: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select2 .select2-container .select2-selection--single {
  height: auto;
}
.custom-select2 .select2-container .select2-selection--single .select2-selection__rendered {
  padding: 0;
  white-space: normal;
  line-height: normal;
  font-size: 11px;
}
.custom-select2 .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px;
  right: 10px
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 0.75rem;
  padding: 0.375rem 0.75rem;
}
.select2-dropdown {
  border: 1px solid #f3f3f3
}
</style>
