<template>
  <PageTemplate :hideTitle="true">
    <!-- <template slot="title">
      <span class="text-gray">Photo Gallery</span>
    </template> -->
    <template slot="header-buttons"></template>
    <template slot="content">
      <div :class="isMobileView ? '' : 'd-flex'">
        <div :class="isMobileView ? '' : 'me-4 mb-2'" :style="{width: isMobileView ? '100%' : '320px'}">
          <LocationSelection :refreshLocation="refreshLocation" />
          <h5>Folders</h5>
          <div class="card mt-2"  style="height: auto" ref="foldersWrapper">
            <ul class="list-group list-group-flush" v-if="getSelectedLocation !== null">
              <li
                class="list-group-item "
                :class="[$route.query.folderId == item.id ? '' : 'list-group-item-action cursor-pointer', theme.themeVersion  === 'dark' ? 'text-light' : 'text-dark']"
                v-for="(item, i) in folders" :key="i + item.id"
                :style="{
                  background: $route.query.folderId == item.id ? 'var(--primary)' : '',
                  color: $route.query.folderId == item.id ? 'white' : '',
                  }"
                @click="changeFolder(item.id, item.name)"
                >
                <div class="d-flex">
                  <i class="bi bi-folder-fill me-2" :class="$route.query.folderId == item.id ? 'text-white' : ''"></i>
                  <span :class="$route.query.folderId == item.id ? 'text-white' : 'text-primary'" >{{item.name}}</span>
                </div>
              </li>
              <li v-if="!folders.length" class="list-group-item list-group-item-action cursor-pointer text-center">
                <em>No Folder Available</em>
              </li>
              <li v-if="role == 'administrator'">
                <button class="btn btn-primary m-auto tp-btn-light d-block my-2" text @click="createFolderDialog = true" ref="createFolderDialogBtn" data-bs-toggle="modal" data-bs-target="#createFolderDialog">
                  <i class="fas  fa-plus me-2"></i>
                  Add Folder
                </button>
              </li>
            </ul>
            <div v-else>Please select Location</div>
          </div>
        </div>
        <div class="flex-grow-1" :style="{width: isMobileView ? '100%' : 'calc(100% - 300px)', maxWidth: isMobileView ? '100%' : 'calc(100% - 300px)'}">
          <div class="card"  ref="detailsWrapper">
            <div class="card-header" v-if="folders.length">
              <h4 v-if="folders.length" class="fs-20">Folder - <strong>{{ $route.query.pageName }}</strong></h4>
              <div v-if="role == 'administrator'">
                <div class="dropdown custom-dropdown mb-0" v-if="isMobileView" >
                  <div class="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                  <div class="dropdown-menu dropdown-menu-right">
                    <a class="dropdown-item" @click="deleteFolderDialog = true" ref="deleteFolderDialogBtn" data-bs-toggle="modal" data-bs-target="#deleteFolderDialog"><i class="fas fa-plus me-2"></i>Delete Folder</a>
                    <a class="dropdown-item" @click="editFolderName()" ref="createFolderDialogBtn" data-bs-toggle="modal" data-bs-target="#createFolderDialog"><i class="fas fa-edit me-2"></i>Edit Folder</a>
                  </div>
                </div>
                <a v-if="!isMobileView" class="btn btn-danger btn-sm me-2" @click="deleteFolderDialog = true" ref="deleteFolderDialogBtn" data-bs-toggle="modal" data-bs-target="#deleteFolderDialog"><i class="fas fa-trash me-2"></i>Delete Folder</a>
                <a v-if="!isMobileView" class="btn btn-primary btn-sm" @click="editFolderName()" ref="createFolderDialogBtn" data-bs-toggle="modal" data-bs-target="#createFolderDialog"><i class="fas fa-edit me-2"></i>Edit Folder</a>
              </div>
            </div>
            <div class="card-body">
              <div  class="d-flex gallery-wrapper flex-wrap">
                <div v-for="(item, i) in photos" :key="i + item.id" class="gallery-thumbnail shadow" >
                  <button class="deletePhotoBtn btn tp-btn-light text-white"
                    v-if="role == 'administrator'"
                    @click="deleteSelectionId = item.id, deleteImgDialog = true" :ref="'deletePhotoBtn' + item.id" data-bs-toggle="modal" data-bs-target="#deleteImgDialog">
                    <i class="bi bi-x-circle-fill"  data-bs-toggle="tooltip" data-bs-placement="top" title="Delete Photo"></i>
                  </button>
                  <img :src="item.url" width="100%" @click="showFullImg(i)" ref="showFullImgBtn" data-bs-toggle="modal" data-bs-target="#showFullImgDialog" />
                </div>
                <div class="uploadBtn-wrapper" v-if="folders.length" @click="uploadImgDialog = true" ref="uploadImgDialogBtn" data-bs-toggle="modal" data-bs-target="#uploadImgDialog">
                  <div class="text-center uploadBtn mb-0">
                    <i class="bi bi-plus-square-dotted d-block" style="font-size: 40px"></i>
                    <!-- <i class="bi bi-file-earmark-image d-block" style="font-size: 40px"></i> -->
                    <span class=" cursor-pointer">ADD PHOTO</span>
                  </div>
                </div>
              </div>
              <div v-if="!folders.length" class="text-center align-items-center justify-content-center d-flex m-auto" style="max-width: 300px; height: 100%">
                <em class="mb-5">No photo available. Please add/create new folder before you can upload photos.</em>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Dialog-->
      <Dialog :id="'createFolderDialog'" @close="createFolderDialog = false" centered title="Add Folder" >
        <template slot="content" ref="createFolderDialog">
          <div class="row">
            <div class="col-md-12 py-3" >
              <label  class="form-label font-w600">Folder Name<span class="text-danger scale5 ms-2"> *</span></label>
              <input v-model="folderName" class="form-control px-2" />
            </div>
          </div>
        </template>
        <template slot="footer">
          <a class="btn btn-danger btn-sm  light" ref="closeDialogBtn" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#createFolderDialog">cancel</a>
          <button class="btn btn-primary btn-sm" :disabled="isLoading" @click="updateFolder" v-if="folderId !== null" >Update Folder</button>
          <button class="btn btn-primary btn-sm" :disabled="isLoading" @click="createFolder" v-else>Add Folder</button>
        </template>
      </Dialog>
      <Dialog :id="'deleteFolderDialog'"  v-if="folders.length" @close="deleteFolderDialog = false" centered small :title="'Delete Folder ' +  $route.query.pageName" >
        <template slot="content">
          <p>Are you sure want to delete?</p>
        </template>
        <template slot="footer">
          <button class="btn btn-danger btn-sm  light" ref="deleteFolderDialog" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#deleteFolderDialog">cancel</button>
          <button class="btn btn-danger btn-sm" :disabled="isLoading" @click="deleteFolder">DELETE Folder</button>
        </template>
      </Dialog>
      <Dialog :id="'deleteImgDialog'"  v-if="photos.length" @close="deleteImgDialog = false" centered small :title="'Delete Photo '" >
        <template slot="content">
          <p>Are you sure want to delete?</p>
        </template>
        <template slot="footer">
          <button class="btn btn-danger btn-sm  light" ref="deleteImgDialog" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#deleteImgDialog">cancel</button>
          <button class="btn btn-danger btn-sm" :disabled="isLoading" @click="deleteImg" data-bs-toggle="modal" data-bs-target="#deleteImgDialog">DELETE Photo</button>
        </template>
      </Dialog>
      <Dialog :gallery="true" :id="'showFullImgDialog'" @close="showFullImgDialog = false, showfullIndex = null" full :title="showfullIndex !== null && photos[showfullIndex] ? photos[showfullIndex].title : ''">
        <template slot="content" v-if="showfullIndex !== null">
          <div class="d-flex  align-items-center">
            <img v-if="photos[showfullIndex]" :src="photos[showfullIndex].url" style="max-height: calc(100vh - 180px); max-width: 100%" class="flex-grow-0" />
          </div>
        </template>
        <template slot="footer">
          <div class="d-flex justify-content-center align-items-center" style="width: 100%">
            <i class="bi bi-arrow-left-square-fill cursor-pointer" style="font-size: 30px" @click="showfullIndex  > 0 ? showfullIndex = showfullIndex - 1 : showfullIndex = photos.length - 1"></i>
            <div class="px-3 text-center" style="min-width: 200px">image {{showfullIndex + 1}}/{{photos.length}}</div>
            <i class="bi bi-arrow-right-square-fill cursor-pointer" style="font-size: 30px" @click="showfullIndex !== photos.length - 1 ? showfullIndex = showfullIndex + 1 : showfullIndex = 0"></i>
            <i class="bi bi-image ms-3 cursor-pointer" v-if="role == 'administrator'" style="font-size: 35px"  @click="setDefaultImage(showfullIndex)" tooltips="Set as default" data-toggle="tooltip" data-placement="left" title="Set as default"></i>
          </div>
        </template>
      </Dialog>
      <Dialog ref="imageW" :id="'uploadImgDialog'" @close="uploadImgDialog = false, clear()" centered medium title="Upload Image">
        <template slot="content">
          <div  v-if="uploadImgDialog">
            <image-uploader
              ref="image"
              :debug="0"
              :maxWidth="800"
              :quality="0.7"
              :autoRotate=true
              outputFormat="blob"
              :preview=true
              :className="['fileinput pt-4 visually-hidden', { 'fileinput--loaded' : hasImage }]"
              :capture="false"
              accept="image/*"
              doNotResize="['gif', 'svg']"
              @input="setImage"
              class="text-center "
              @onUpload="startUpload()"
              @onComplete="endUpload"
              :disabled="!isLoading"
            >
              <label for="fileInput" class="text-center uploadBtn-wrapper mb-0 d-block m-auto py-3" slot="upload-label" v-if="!hasImage">
                <i class="bi bi-file-earmark-image d-block" style="font-size: 40px"></i>
                <span class=" cursor-pointer">ADD PHOTO</span>
              </label>
              <label for="fileInput" ref="changeImg" class="btn btn-rounded btn-outline-primary mt-3" v-else slot="upload-label">
                Change Photo
              </label>
            </image-uploader>
          </div>
          <div class="mt-4">
            <label class="form-label">Photo Title</label>
            <input type="text" v-model="imageTitle" placeholder=""  class="form-control" >
          </div>
        </template>
        <template slot="footer">
          <button class="btn btn-danger btn-sm  light" ref="closeUploadImgDialog" @click="clear()" :disabled="isLoading" data-bs-toggle="modal" data-bs-target="#uploadImgDialog">cancel</button>
          <button class="btn btn-primary btn-sm" :disabled="!hasImage" @click="uploadImg" data-bs-toggle="modal" data-bs-target="#uploadImgDialog">Upload Photo</button>
        </template>
      </Dialog>
    </template>
  </PageTemplate>
</template>
<script>
import Dialog from '@/components/Templates/Dialog'
import PageTemplate from '@/components/Templates/PageTemplate'
import ImageUploader from 'vue-image-upload-resize'
import LocationSelection from '@/components/LocationSelection'
export default {
  components: {
    PageTemplate,
    Dialog,
    ImageUploader,
    LocationSelection
  },
  data () {
    return {
      isLoading: false,
      locations: [],
      selectedLocation: null,
      folders: [],
      createFolderDialog: false,
      deleteFolderDialog: false,
      deleteImgDialog: false,
      uploadImgDialog: false,
      showFullImgDialog: false,
      folderName: '',
      folderId: null,
      photos: [],

      // image upload
      hasImage: false,
      image: null,

      deleteSelectionId: null,
      showfullIndex: null,

      // upload image
      imageTitle: '',
      refreshLocation: 0
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    },
    getSelectedLocation () {
      return this.$store.getters['locations/selectedLocation']
    },
    role () {
      return this.$store.getters['user/role']
    }
  },
  created () {
    this.$emit('pageTitle', 'Photos')

    if (this.$route.query.folderId !== null) {
      this.getPhotos(this.$route.query.folderId)
    } else {
      this.getFolder(this.getSelectedLocation.id)
    }
  },
  watch: {
    getSelectedLocation: {
      deep: true,
      handler (val) {
        if (val !== null) {
          this.getFolder(val.id)
        } else {
          this.folders = []
        }
      }
    },
    folders: {
      deep: true,
      handler (val) {
        if (val.length) {
          if (val[0].name !== this.$route.query.pageName && val[0].id !== parseInt(this.$route.query.folderId)) {
            this.$router.push({
              query: {
                pageName: val[0].name,
                folderId: val[0].id
              }
            }).catch(() => {})
          }
        } else {
          this.$router.push({
            query: {
              pageName: null,
              folderId: null
            }
          }).catch(() => {})
        }
      }
    },
    '$route.query.folderId' (val) {
      this.getPhotos(val)
    }
  },
  methods: {
    setImage (file) {
      this.hasImage = true
      this.image = file
    },
    setDefaultImage (index) {
      const payload = {
        photoId: this.photos[index].id,
        folderId: this.$route.query.folderId
      }
      this.$store.dispatch('setDefaultPhoto', payload).then(res => {
        this.$toast.success('Set default photo successfull')
        this.refreshLocation = this.refreshLocation + 1
      }).catch(err => {
        this.$toast.error(err.response.data.error)
      })
    },
    getFolder (locationId) {
      this.$store.dispatch('getFolders', locationId).then(el => {
        this.folders = el.data
      })
    },
    changeFolder (id, name) {
      this.$router.push({
        query: {
          pageName: name,
          folderId: id
        }
      })
    },
    startUpload () {
      this.isLoading = true
      this.loading(this.$refs.imageW, 'show')
    },
    endUpload () {
      this.loading(this.$refs.imageW, 'hide')
      this.isLoading = false
      document.querySelector('.img-preview').style.display = 'block'
    },
    createFolder () {
      const payload = {
        location_id: this.getSelectedLocation.id,
        name: this.folderName
      }
      this.loading(this.$refs.createFolderDialog, 'show')
      this.loading(this.$refs.foldersWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('createFolder', payload).then(e => {
        console.log(this.getSelectedLocation.id)
        this.loading(this.$refs.createFolderDialog, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.getFolder(this.getSelectedLocation.id)
        this.folderName = ''
        this.$refs.closeDialogBtn.click()
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      }).catch(err => {
        console.log(err)
        const errors = err.response.data.error
        let textErr = ''
        for (var field in errors) {
          for (var fieldErr in errors[field]) {
            textErr += errors[field][fieldErr] + '</br>'
          }
        }
        this.$toast.error(textErr)
        this.loading(this.$refs.createFolderDialog, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.isLoading = false
      })
    },
    getPhotos (val) {
      if (JSON.parse(val) !== null) {
        this.loading(this.$refs.detailsWrapper, 'show')
        this.isLoading = true
        this.$store.dispatch('getPhotos', val).then(res => {
          this.photos = []
          this.photos = res.data
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
        }).catch(err => {
          console.log(err)
          this.photos = []
          this.$toast.error(err.response.data.error)
          this.loading(this.$refs.detailsWrapper, 'hide')
          this.isLoading = false
        })
      } else this.photos = []
    },
    getFolderName (id) {
      const folderName = this.folders.find(x => x.id === parseInt(this.$route.query.folderId)).name
      return folderName
    },
    editFolderName () {
      this.folderId = this.$route.query.folderId
      this.folderName = this.folders.find(x => x.id === parseInt(this.$route.query.folderId)).name
      this.createFolderDialog = true
    },
    updateFolder () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.loading(this.$refs.foldersWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('updateFolder', {
        location_id: this.getSelectedLocation.id,
        id: this.$route.query.folderId,
        name: this.folderName
      }).then(e => {
        this.folderId = null
        this.folderName = ''
        this.createFolderDialog = false
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.isLoading = false
        this.getFolder(this.getSelectedLocation.id)
        this.$refs.closeDialogBtn.click()
      }).catch(err => {
        console.log(err)
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.isLoading = false
      })
    },
    deleteFolder () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.loading(this.$refs.foldersWrapper, 'show')
      this.isLoading = true
      this.$store.dispatch('deleteFolder', this.$route.query.folderId).then(e => {
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.isLoading = false
        this.getFolder(this.getSelectedLocation.id)
        this.$refs.deleteFolderDialogBtn.click()
        this.deleteFolderDialog = false
        this.$toast.success(e)
      }).catch(err => {
        console.log(err)
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.loading(this.$refs.foldersWrapper, 'hide')
        this.isLoading = false
      })
    },
    deleteImg () {
      console.log(this.deleteImgDialog, this.deleteSelectionId)
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      const payload = {
        folderId: this.$route.query.folderId,
        photoId: this.deleteSelectionId
      }
      this.$store.dispatch('deletePhoto', payload).then(e => {
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.isLoading = false
        this.getPhotos(this.$route.query.folderId)
        this.deleteImgDialog = false
        this.$toast.success(e)
        this.deleteSelectionId = null
      }).catch(err => {
        console.log(err)
        this.$toast.error(err.response.data.error)
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.isLoading = false
      })
    },
    uploadImg () {
      this.loading(this.$refs.detailsWrapper, 'show')
      this.isLoading = true
      const payload = {
        id: this.$route.query.folderId,
        file: this.image,
        title: this.imageTitle
      }
      this.$store.dispatch('addPhoto', payload).then(res => {
        this.getPhotos(this.$route.query.folderId)
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.isLoading = false
        this.clear()
      }).catch(err => {
        console.log(err)
        this.clear()
        const errors = err.response.data.error
        let textErr = ''
        for (var field in errors) {
          for (var fieldErr in errors[field]) {
            textErr += errors[field][fieldErr] + '</br>'
          }
        }
        this.$toast.error(textErr)
        this.loading(this.$refs.detailsWrapper, 'hide')
        this.isLoading = false
      })
    },
    clear () {
      this.image = null
      this.hasImage = false
      this.imageTitle = ''
      this.uploadImgDialog = false
      // document.getElementsByClassName('img-preview').style.display = 'none'
    },
    showFullImg (index) {
      this.showfullIndex = index
      this.showFullImgDialog = true
    }
  }
}
</script>
<style>
.gallery-wrapper > div {
  height: 0;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
}
.uploadBtn-wrapper {
  position: relative;
  margin: 5px;
  outline: 3px dashed #ccc;
  outline-offset: -3px;
  border-radius: 10px;
  outline-style: dashed;
  transition: ease all 0.3s;
  cursor: pointer;
}
.uploadBtn-wrapper:hover, .gallery-thumbnail:hover {
  background: rgba(0, 0, 0, 0.1);
}
.uploadBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}
.gallery-thumbnail {
  border-radius: 10px;
  position: relative;
  margin: 5px;
  overflow: hidden;
  transition: ease all 0.3s;
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  transition: ease all 0.3s;
  border: 1px solid #ddd;
}
.gallery-thumbnail:hover {
  background: rgba(0, 0, 0, 1);
}
.gallery-thumbnail:hover img {
  opacity: 0.6;
}
.gallery-thumbnail img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 200%;
  max-height: 200%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  transition: ease all 0.3s;
}
.gallery-thumbnail .deletePhotoBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  font-size: 20px;
  height: auto;
  line-height: 1;
  opacity: 0;
  transition: ease all 0.3s;
  padding: 0px;
  border: 0px !important;
}
.gallery-thumbnail:hover .deletePhotoBtn {
  opacity: 1;
}
.deletePhotoBtn:hover {
  background: transparent !important;
  padding: 0px;
  margin: 0px;
}

.img-preview {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px;
  display: block; margin: auto;
  outline: 3px dashed #ccc;
  outline-offset: 0;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .gallery-wrapper > div {
    width: 100%;
    padding-bottom: calc(100% - 10px);
    flex-basis: 100%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .gallery-wrapper > div {
    width: 50%;
    max-width: calc(50% - 10px);
    padding-bottom: 50%;
  }
 }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .gallery-wrapper > div {
    width: 33.333%;
    max-width: calc(33.333% - 10px);
    padding-bottom: 33.333%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .gallery-wrapper > div {
    width: 25%;
    max-width: calc(25% - 10px);
    padding-bottom: 25%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .gallery-wrapper > div {
    width: 20%;
    max-width: calc(20% - 10px);
    padding-bottom: 20%;
  }
}

</style>
