<template>
<div v-resize="onResize">
  <div v-if="this.$route.name == 'login' || (this.$route.name == 'error-404' && !isLoggedIn)" class="authincation h-100">
    <router-view/>
  </div>
  <div v-else id="main-wrapper">
    <NavHeader/>
    <Header :pageTitle="pageTitle" :themeVer="theme == null ? setThemeSetting.themeVersion : theme.themeVersion" @themeVer="changeThemeVer"/>
    <Sidebar/>
    <div class="content-body">
      <div class="container-fluid">
        <router-view @pageTitle="setPageTitle"/>
      </div>
    </div>
    <!-- <Footer/> -->
  </div>
</div>
</template>
<script>
// require('../public/js/dlabnav-init')
import resize from 'vue-resize-directive'
import NavHeader from '@/components/Base/NavHeader'
import Header from '@/components/Base/Header'
import Sidebar from '@/components/Base/Sidebar'
// import Footer from '@/components/Base/Footer'
import mixinFn from '@/mixins'

export default {
  directives: { resize },
  name: 'app',
  mixins: [mixinFn],
  data: () => {
    return {
      heightTimeout: null,
      widthTimeout: null,
      pageTitle: '',
      setThemeSetting: {
        themeVersion: 'light',
        sidebarStyle: 'compact',
        typography: 'poppins',
        layout: 'vertical',
        navHeaderbg: 'color_9',
        headerbg: 'color_1',
        sidebarbg: 'color_9',
        sidebarPosition: 'fixed',
        headerPosition: 'fixed',
        container: 'wide',
        direction: 'ltr',
        primaryColor: 'color_9'
      }
    }
  },
  components: { NavHeader, Header, Sidebar },
  computed: {
    isLoggedIn () {
      return this.$store.getters['user/isLoggedIn']
    },
    theme () {
      return this.$store.getters['config/theme']
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val === false) {
          this.setThemeSetting.themeVersion = 'light'
          this.changeTheme(this.setThemeSetting)
        }
      }
    }
  },
  created () {
    this.changeTheme(this.theme == null ? this.setThemeSetting : this.theme)
    window.addEventListener('resize', this.myEventHandler)
  },
  mounted () {
    this.checkLogin()
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  methods: {
    checkLogin () {
      if (this.$store.getters['user/isLoggedIn']) {
        const vm = this
        this.axios.get('/sanctum/csrf-cookie').then(response => {
          vm.$store.dispatch('user/getData', this.$store.getters['user/token']).then((response) => {
            if (vm.$route.name === 'login') {
              location.href = '/dashboard'
              /* vm.$router.push({ name: 'dashboard' }) */
            }
          })
        })
      }
    },
    setPageTitle (title) {
      console.log(title)
      this.pageTitle = title
    },
    myEventHandler (e) {
      if (this.timeout) clearTimeout(this.timeout)
      this.widthTimeout = setTimeout(() => {
        this.changeTheme(this.theme == null ? this.setThemeSetting : this.theme)
      }, 50)
      this.height = window.innerHeight
      this.width = window.innerWidth
    },
    changeThemeVer (val) {
      this.setThemeSetting.themeVersion = val
      this.changeTheme(this.setThemeSetting)
    }
  }
}
</script>
<style>
#main-wrapper { min-height: 100vh;}

.custom-wrapper-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 1.875rem;
  flex-wrap: wrap;
}
.icon_parameter {
  /* width: 60px;
  min-width: 60px;
  height: 60px; */
  padding: 10px 20px;
  background: #2c745f;
  color: white;
  border: 0px solid rgba(0,0,0,0.2);
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 7px 16px -5px rgba(0,0,0,0.58);
  box-shadow: 0px 7px 16px -5px rgba(0,0,0,0.58);
  opacity: 0.6;
  transition: all ease 0.3s;
  margin: 3px 10px;
  cursor: pointer;
}
.icon_parameter.active, .icon_parameter:hover {
  border-color: black;
  opacity: 1;
  transform: scale(1.2);
}

.icon_parameter img {
  width: 85%;
  margin: auto
}

.reportrange-text {
  display: flex;
  align-items:center;
  /* border-top-left-radius: 0;
  border-bottom-left-radius: 0; */
  border-radius: 0;
  width: 100%;
  border-right: 0px solid #ccc;
}
.custom-datepicker .vd-picker__input input {
  font-size: inherit !important;
}

.active-card {
  border: 1px solid var(--primary);
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 15%)
}

.custom-datetime-picker #tj-datetime-input {
  display: block;
  width: 100%;
  padding: 0.3125rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #393939;
  background-color: #fff;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 16px 12px !important;
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  appearance: none;
  height: 3.5rem;
}
.custom-datetime-picker .year-month-wrapper, .custom-datetime-picker .activePort,
.custom-datetime-picker .nav-l[data-v-4bd11526], .custom-datetime-picker .nav-r[data-v-4bd11526] {
  background-color: var(--primary) !important
}
.custom-datetime-picker .days[data-v-4bd11526] {
  color: #6c6c6c
}
.custom-datetime-picker .okButton {
  color: var(--primary) !important
}
.scroll-hider ul {
  width: 60px !important
}

/* Utilities */
/* Flex */
.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }
.flex-shrink-1 { flex-shrink: 1; }
.flex-shrink-0 { flex-shrink: 0; }

.cursor-pointer {
  cursor: pointer;
}

/* display */
.hide-on-empty:empty { display: none; }

[data-theme-version="dark"] a.custom-link {
  color: var(--primary) !important
}
[data-theme-version="dark"] .form-control {
  border-color: #464674 !important;
}
[data-theme-version="dark"] .input-group-text {
  border-color: #464674 !important;
}
.mx-input-wrapper input {
  min-height: 42px;
  border-radius: 0 !important;
}

.mx-icon-calendar, .mx-icon-clear { display: none !important;}
</style>
