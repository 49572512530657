<template>
  <div style="min-height: 60vh" class="d-flex align-items-center  justify-content-center">
    <div style="max-width:960px" class="d-flex align-items-center  justify-content-center flex-wrap">
      <div style="min-width: 360px" class="mt-3 text-center text-sm-center text-lg-start">
        <h3><strong>Cocoa Smart Nutrient for Android</strong></h3>
        <span style="font-size: 16px">Download our Android app</span>
        <div class="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
          <div class="text-center">
            <div>Scan <strong>QR Code</strong></div>
            <!-- <img src="@/assets/qrcode.png" style="max-width: 100px; width: 100%" /> -->
          </div>
          <div class="px-3">or</div>
          <a class="btn btn-primary" download href="https://cocoanutrientiot.com/download-app/Cocoa%20Smart%20Nutrient.apk"><i class="fas fa-download me-2"></i>Download App</a>
        </div>
      </div>
      <div style="min-width: 360px" class="text-center mt-3">
        <!-- <img src="@/assets/image_app.png" style="max-width: 420px; width: 80%" /> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'dashboard',
  components: {
  },
  data () {
    return {
    }
  },
  created () {
    this.$emit('pageTitle', 'Download APK')
  }
}
</script>
