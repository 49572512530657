<template>
  <div>
    <div class="row">
      <div class="col-xl-12  col-md-12">
          <div  class="p-3 mb-2 border rounded" v-for="(item, i) in valueV"  :key="i + 'sy'" style="background-color: #eee">
            <div class="row">
              <div class="col-md-12 d-flex justify-content-between align-items-center" >
                <h4 class="mb-0">
                  {{item.name}}
                </h4>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-6 py-3" >
                <label  class="form-label font-w600">Status<span class="text-danger scale5 ms-2"> *</span></label>
                <div class="pt-3 pb-2">
                  <label class="radio-inline me-3"><input v-model="item.status" :disabled="!editMode" :value="false" type="radio" /> Disable</label>
                  <label class="radio-inline me-3"><input v-model="item.status" :disabled="!editMode" :value="true" type="radio" /> Enable</label>
                </div>
              </div>
              <div class="col-xl-5 col-lg-12 col-md-12 py-3" >
                <label  class="form-label font-w600">Data Type<span class="text-danger scale5 ms-2">*</span></label>
                <div class="pb-2">
                  <select class="form-control" v-model="item.data_type" :disabled="true">
                    <option v-for="(list, index) in types" :value="list" :key="index">{{list}}</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3" v-if="item.data_type =='Decimal'" >
                <label  class="form-label font-w600">Decimal</label>
                <input type="number" v-model="item.decimal" :disabled="!editMode || item.type !== 'Additional'" placeholder="" class="form-control " >
              </div>
              <div class="col-xl-2 col-lg-3 col-md-3 col-sm-6 pt-3 pb-3" v-if="item.name == 'Temperature'">
                <label  class="form-label font-w600">Unit</label>
                <input type="text" v-model="item.unit" :disabled="true" placeholder="" class="form-control " >
              </div>
            </div>
            <div class="row" >
              <div class="col-12 pt-3" >
                <label  class="form-label font-w600">Ranges<span class="text-danger scale5 ms-2"> *</span></label>
                <div class="d-none d-lg-block">
                  <div class="input-group mb-2 " v-if="editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Min</span>
                    <input type="number" class="form-control" v-model="ranges[i].min" >
                    <span class="input-group-text">Max</span>
                    <input type="number" class="form-control" v-model="ranges[i].max" >
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text py-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].min || !ranges[i].max || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>
                  <template>
                    <div class="input-group mb-2"
                      :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                      v-for="(rangeList, index) in item.ranges" :key="i + index + 'rg5'">
                      <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index +
                          1}}</strong></span>
                      <span class="input-group-text">Min</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="item.ranges[index].min">
                      <span class="input-group-text">Max</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.max">
                      <span class="input-group-text">Status</span>
                      <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                        <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                        <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                      </select>
                      <span class="input-group-text bg-danger py-0" v-if="editMode">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                            class="fas fa-times"></i></button>
                      </span>
                    </div>
                  </template>
                  <div v-if="!item.ranges.length" class="text-center p-3 text-light form-control">
                    <em>No Range Added</em>
                  </div>
                </div>
                <div class="d-block d-lg-none">
                  <div class="input-group mb-1 " v-if="editMode">
                    <span class="input-group-text bg-primary text-white " style="max-width: 50px"><strong>ADD</strong></span>
                    <span class="input-group-text">Min</span>
                    <input type="number" class="form-control" v-model="ranges[i].min" >
                    <span class="input-group-text">Max</span>
                    <input type="number" class="form-control" v-model="ranges[i].max" >
                  </div>
                  <div class="input-group mb-3 " v-if="editMode">
                    <span class="input-group-text">Status</span>
                    <select class="form-select form-control" v-model="ranges[i].status">
                      <option :selected="ranges[i].status == null ? true : false" :value="null">Choose...</option>
                      <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                    </select>
                    <span class="input-group-text py-0 bg-primary">
                      <button class="btn btn-sm tp-btn btn-rounded text-white" @click="addRange(i)" :disabled="!ranges[i].min || !ranges[i].max || !ranges[i].status"><i class="fa fa-plus"></i></button>
                    </span>
                  </div>
                  <template v-for="(rangeList, index) in item.ranges">
                    <div class="input-group mb-1"
                      :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                       :key="i + index + 'rg1'"
                      >
                      <span class="input-group-text bg-dark text-white" style="min-width: 50px"><strong>{{index +
                          1}}</strong></span>
                      <span class="input-group-text">Min</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="item.ranges[index].min">
                      <span class="input-group-text">Max</span>
                      <input type="number" :disabled="!editMode" class="form-control" v-model="rangeList.max">
                    </div>
                    <div class="input-group mb-3"
                      :class="!rangeList.min || !rangeList.max || !rangeList.status ? 'input-danger-o' : ''"
                       :key="i + index + 'rg2'"
                      >
                      <span class="input-group-text">Status</span>
                      <select :disabled="!editMode" class="form-select form-control" v-model="rangeList.status">
                        <option :selected="rangeList.status== null ? true : false" :value="null">Choose...</option>
                        <option v-for="(list, index) in statusRange" :value="list" :key="index">{{list}}</option>
                      </select>
                      <span class="input-group-text bg-danger py-0" v-if="editMode">
                        <button class="btn btn-sm tp-btn btn-rounded text-white" @click="deleteApplianceRange(i, index)"><i
                            class="fas fa-times"></i></button>
                      </span>
                    </div>
                  </template>
                  <div v-if="!item.ranges.length" class="text-center p-3 text-light form-control">
                    <em>No Range Added</em>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Dialog from '@/components/Templates/Dialog'
export default {
  props: ['valueV', 'editMode'],
  components: {
    // Dialog
  },
  data () {
    return {
      editmode: true,
      addNewDialog: false,
      ranges: [],
      form: {
        name: '',
        data_type: 'Text',
        status: null,
        unit: '',
        type: 'Additional',
        decimal: 1
      },
      min: null,
      max: null,
      statusR: null,
      statusRange: [
        'Normal',
        'Warning',
        'Alert'
      ],
      types: [
        'Number',
        'Decimal',
        'Boolean',
        'Text'
      ]
    }
  },
  computed: {
    theme () {
      return this.$store.getters['config/theme']
    },
    isMobileView () {
      return this.$store.getters['global/mobileView']
    }
  },
  created () {
    this.populateRanges()
  },
  watch: {
    'form.data_type' (val) {
    }
  },
  methods: {
    populateRanges () {
      console.log(this.valueV)
      this.valueV.forEach(el => {
        if (el.data_type === 'Decimal' || el.data_type === 'Number') {
          this.ranges.push({ min: 0, max: 0, status: null })
        } else if (el.data_type === 'Boolean') {
          this.ranges.push({ value: '', status: null })
        }
      })
      console.log(this.ranges)
    },
    addRange (i) {
      if (this.valueV[i].data_type === 'Decimal' || this.valueV[i].data_type === 'Number') {
        const payload = {
          min: this.ranges[i].min,
          max: this.ranges[i].max,
          status: this.ranges[i].status
        }
        this.valueV[i].ranges.push(payload)

        this.ranges[i].min = 0
        this.ranges[i].max = 0
        this.ranges[i].status = null
      } else if (this.valueV[i].data_type === 'Boolean') {
        const payload = {
          value: this.ranges[i].value,
          status: this.ranges[i].status
        }
        this.valueV[i].ranges.push(payload)
        this.ranges[i].value = 0
        this.ranges[i].status = null
      }
    },
    deleteApplianceRange (i, index) {
      this.valueV[i].ranges.splice(index, 1)
    }
  }
}
</script>

<style>

</style>
